.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.row {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.column {
    display: flex;
    flex-direction: column;
}

.button-accent {
    border: 0;
    width: auto;
    cursor: pointer;
    color: #fff;
    text-align: center;
    background-color: #ad4665;
    background-image: linear-gradient(320deg,#ad4665,#d65870);
    border-radius: 50px;
    padding: 19px 36px;
    font-size: 16px;
    font-weight: 500;
    line-height: 106%;
    transition: box-shadow .3s cubic-bezier(.25,.46,.45,.94),filter .3s cubic-bezier(.25,.46,.45,.94);
    display: inline;
    background-color: #fbfafa;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.button-accent:hover {
    filter: brightness(118%);
    box-shadow: 0 15px 30px rgba(231,117,43,.4)
}

select {
    /* styling */
    background-color: white;
    border: thin solid blue;
    border-radius: 4px;
    display: inline-block;
    font-family: 'Plus Jakarta Sans', sans-serif;
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.5em 1em;

    /* reset */
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
}


select.minimal {
    background-image:
            linear-gradient(45deg, transparent 50%, gray 50%),
            linear-gradient(135deg, gray 50%, transparent 50%),
            linear-gradient(to right, #ccc, #ccc);
    background-position:
            calc(100% - 20px) calc(1em + 2px),
            calc(100% - 15px) calc(1em + 2px),
            calc(100% - 2.5em) 0.5em;
    background-size:
            5px 5px,
            5px 5px,
            1px 1.5em;
    background-repeat: no-repeat;
}

select.minimal:focus {
    background-image:
            linear-gradient(45deg, green 50%, transparent 50%),
            linear-gradient(135deg, transparent 50%, green 50%),
            linear-gradient(to right, #ccc, #ccc);
    background-position:
            calc(100% - 15px) 1em,
            calc(100% - 20px) 1em,
            calc(100% - 2.5em) 0.5em;
    background-size:
            5px 5px,
            5px 5px,
            1px 1.5em;
    background-repeat: no-repeat;
    border-color: green;
    outline: 0;
}

label {
    font-family: 'Plus Jakarta Sans', sans-serif;
}


